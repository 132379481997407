.error {
  color: red;
}
.container {
  position: relative;
  justify-content: center;
  width: 100%;
  display: flex;
}
.leftColumn {
  display: flex;
  position: relative;
  width: 340px;
  height: 1016px;
}
.rightColumn {
  display: flex;
  position: relative;
  width: 340px;
  height: 1016px;
}
.mainColumn {
  display: flex;
  position: relative;
}
.sectionCards {
  display: flex;
  position: relative;
}
