.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.avatar-placeholder {
  width: 200px;
  height: 150px;
  margin-right: 12px;
  margin-bottom: 5px;
}

.text-placeholder {
  height: 8px;
  margin-bottom: 5px;
}

.placeholder {
  display: inline-block;
  background-color: #b0c0c7;
  animation-name: shine;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
}

@keyframes shine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 1;
  }
}

/* wikipedia css */

.wikipedia {
  font-family: "Arial", "ヒラギノ角ゴ3", "Meiryo" !important;
  background: #fff !important;
  font-size: 17px !important;

  h1 {
    border: 0px !important;
    font-family: "Arial", "ヒラギノ角ゴ3", "Meiryo" !important;
    font-weight: 700 !important;
    font-size: 36px !important;
  }
  h2 {
    border: 0px !important;
    font-family: "Arial", "ヒラギノ角ゴ3", "Meiryo" !important;
    font-weight: 400 !important;
    font-size: 26px !important;
  }
  h3 {
    border: 0px !important;
    font-family: "Arial", "ヒラギノ角ゴ3", "Meiryo" !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }

  table,
  td,
  th {
    border: 0px !important;
    border-collapse: collapse !important;
    padding: 5px !important;
  }
  ul,
  li {
    background: transparent !important;
    border: 0px !important;
    line-height: 1.8 !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  li span {
    background: transparent !important;
    border: 0px !important;
    font-size: 14px !important;
  }
  .reference,
  hr {
    display: none;
  }
  dl {
    font-weight: 300 !important;
  }
  dt {
    font-style: italic;
    font-size: 16px !important;
    margin-top: 10px !important;
  }
  dd {
    font-size: 15px !important;
  }
  #p-logo a {
    background-image: url("http://icons.iconarchive.com/icons/sykonist/popular-sites/128/Wikipedia-icon.png") !important;
  }
  code {
    padding: 3px !important;
    background: transparent !important;
    color: #39892f !important;
  }
  tt {
    font-family: "Source Code Pro", "Lucida Sans Unicode", "Courier New" !important;
    font-size: 16px !important;
  }
  a,
  a:link,
  a:hover,
  a:visited {
    color: #0645ad !important;
  }
  .new,
  .new:link,
  .new:visited {
    color: #cd5b45 !important;
  }
  blockquote {
    border: 0px !important;
    font-style: italic !important;
    font-size: 20px !important;
    margin: 35px 75px !important;
    line-height: 1.5 !important;
    font-weight: 300 !important;
    background: transparent !important;
  }
  select {
    padding: 0px 10px;
  }
  .mw-ui-button,
  .mw-ui-progressive {
    border-radius: 4px !important;
    border: 1px solid #347bff !important;
    color: #347bff !important;
    background: #fff !important;
    text-shadow: 0px 0px 0px;
  }
  .mw-ui-button.mw-ui-progressive:hover,
  .mw-ui-button.mw-ui-progressive:focus,
  .mw-ui-button.mw-ui-progressive:active,
  .mw-ui-button:hover,
  .mw-ui-progressive:hover,
  .mw-ui-button:focus,
  .mw-ui-progressive:focus {
    box-shadow: 0px 0px 0px !important;
    text-shadow: 0px 0px 0px !important;
    border: 1px solid #347bff !important;
  }
  #wpSave,
  #wpPreview,
  #wpDiff {
    border-radius: 4px !important;
    border: 1px solid #347bff !important;
    color: #347bff !important;
    background: #fff !important;
    text-shadow: 0px 0px 0px;
    padding: 0.5em 1em;
    cursor: pointer;
  }
  #wpSave:hover,
  #wpPreview:hover,
  #wpDiff:hover {
    color: rgba(52, 123, 255, 0.5) !important;
  }

  /* Search */
  #simpleSearch {
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    background: transparent !important;
    color: #000 !important;
    border-radius: 30px !important;
    padding: 7px 7px 0px 7px !important;
  }
  #searchInput {
    border: 0px !important;
    background: transparent !important;
    font-family: "Roboto" !important;
    font-size: 16px !important;
    padding: 4px 7px 7px 7px !important;
  }
  #searchButton {
    right: 7px !important;
  }

  .mw-ui-input {
    font-family: "Roboto" !important;
    border: 1px solid #347bff !important;
    color: #000 !important;
    background: #fff !important;
    font-size: 16px !important;
  }
  .mw-ui-input:focus {
    box-shadow: 0px 0px 0px !important;
  }
  .suggestions {
    font-weight: 300 !important;
    font-size: 15px !important;
    max-width: 300px !important;
    margin-top: 15px !important;
    border: 0px !important;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
  }
  .suggestions-results {
    border: 0px !important;
  }
  .suggestions-result {
    padding: 10px !important;
  }
  .imeselector,
  #searchEngines {
    display: none !important;
  }
  .mw-search-formheader {
    border: 0px !important;
    background: transparent !important;
  }
  .mw-search-result-heading,
  .searchresult,
  .searchmatch {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 3px 0px !important;
  }
  .searchmatch {
    font-weight: 700 !important;
  }
  .mw-search-result-data,
  .mw-search-createlink {
    display: none;
  }

  #mw-page-base,
  .vectorTabs {
    background: transparent !important;
    border: 0px !important;
  }
  #content {
    border: 0px !important;
    margin-left: 215px !important;
  }
  .portal {
    background: transparent !important;
  }
  #mw-panel {
    width: 200px !important;
    background: rgba(0, 0, 0, 0.04);
  }
  #mw-head {
    opacity: 1;
    -webkit-transition-property: opacity !important;
    -moz-transition-property: opacity !important;
    -o-transition-property: opacity !important;
    -ms-transition-property: opacity !important;
    transition-property: opacity !important;
    -webkit-transition-duration: 0.5s !important;
    -moz-transition-duration: 0.5s !important;
    -o-transition-duration: 0.5s !important;
    -ms-transition-duration: 0.5s !important;
    transition-duration: 0.25s !important;
  }
  #mw-head:hover {
    opacity: 1 !important;
  }

  .uls-settings-trigger {
    display: none !important;
  }

  #left-navigation {
    margin-left: 233px !important;
  }

  .wikitable {
    font-weight: 300 !important;
  }
  .wikitable td,
  .wikitable th {
    border: 1px dashed rgba(0, 0, 0, 0.07) !important;
  }

  pre {
    line-height: 1.3 !important;
    font-family: "Source Code Pro", "Lucida Sans Unicode", "Couriew New",
      "Arial" !important;
    border: 0px !important;
    background: 0px !important;
    font-size: 16px !important;
  }
  .mw-code {
    border: 0px !important;
    padding: 17px !important;
    background: 0px !important;
    font-size: 16px !important;
  }

  .toc {
    background: transparent !important;
    border-style: dashed !important;
    display: block !important;
    max-width: 300px !important;
  }
  .toctoggle {
    display: none !important;
  }

  .thumbinner,
  .thumb,
  .toccolours,
  .graytable {
    background: transparent !important;
    border: 0px !important;
    font-size: 16px !important;
  }
  .thumbimage {
    border: 0px !important;
  }
  .thumbcaption {
    font-style: italic !important;
    margin: 7px 0px !important;
  }
  .thumbimage:hover {
    opacity: 0.85 !important;
  }

  .dablink {
    margin: 14px 0px !important;
    font-weight: 300 !important;
    font-size: 18px !important;
  }

  #siteSub,
  .flaggedrevs_preview {
    display: none !important;
  }

  .ambox,
  .tmbox,
  .Note,
  .informationbox {
    margin: 5px 0px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    border: 1px dashed #aaa !important;
    max-width: 550px !important;
    font-weight: 300 !important;
  }
  .ambox td,
  .tmbox td {
    padding: 10px !important;
  }
  .ambox-text-small,
  .mbox-text,
  .Note td {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #000 !important;
  }
  .ambox-image,
  .mbox-image {
    display: none;
  }
  #request_for_deletion,
  .ambox-serious {
    background: rgba(255, 0, 0, 0.1) !important;
    border: 1px dashed rgba(255, 0, 0, 0.15) !important;
    color: #a00000 !important;
  }
  .tmbox-notice,
  .tmbox-move,
  .Note {
    background: rgba(150, 75, 0, 0.1) !important;
    border: 1px dashed rgba(150, 75, 0, 0.15) !important;
  }
  .informationbox {
    background: #fff !important;
    padding: 10px !important;
    font-size: 14px !important;
  }
  .editOptions {
    background: #fff !important;
    border: 0px !important;
  }

  .catlinks {
    background: transparent !important;
    border: 1px dashed #aaa !important;
    font-size: 16px !important;
  }

  .infobox,
  .tright,
  .toccolours {
    float: right;
    border: 0px !important;
    background: transparent !important;
    border-collapse: collapse !important;
  }
  .infobox td,
  .infobox th,
  .tright td,
  .tright th,
  .toccolours td,
  .toccolours th {
    padding: 5px !important;
    background: transparent !important;
    font-weight: 400 !important;
    border: 1px dashed rgba(0, 0, 0, 0.07) !important;
  }
  .image img:hover {
    opacity: 0.85 !important;
  }

  .globegris {
    background: transparent !important;
  }

  .mw-editsection-bracket,
  .references-small b,
  .mw-cite-backlink,
  .plainlinksneverexpand {
    display: none !important;
  }
  #floating_object {
    display: none;
  }

  .navbox,
  .nowraplinks {
    margin: 10px 0px !important;
    border-collapse: collapse !important;
  }
  .navbox td,
  .navbox th,
  .nowraplinks td,
  .nowraplinks th {
    padding: 9px 6px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    border: 1px solid #fff !important;
  }
  .NavFrame {
    border: 0px !important;
    background: transparent !important;
    padding: 0px !important;
  }
  .NavHead {
    margin: 10px 0px !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    background: transparent !important;
  }
  .Boxmerge {
    border: 0px !important;
  }

  #coordinates {
    display: none !important;
  }
  #mw-fr-revisiontag,
  #mw-fr-reviewnotice {
    display: none !important;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
