.card-text-limited {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.card {
  height: 496px;
  width: 328px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px;
  border-radius: 8px;
  color: grey;
}
.img {
  height: 27vh;
}
.author {
  bottom: 3vh;
  left: 3vh;
}
.star {
  bottom: 3vh;
  right: 3vh;
  font-size: 9px;
}
.fa-star {
  color: yellow;
}
.cardTitle{
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-body{
  background-color: #eee;
  color: #000;
  height: 140px;
}